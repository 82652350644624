<template>
  <div class="copyright">© EKOS - Žitenice s.r.o.</div>
</template>

<script setup lang="ts">

</script>

<style scoped>

.copyright{
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  color: #0A9E09;
  font-weight: 600;
}

</style>